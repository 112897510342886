import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'
import { cubicBezier } from '@styles/vars/cubicBezier.style'

export const ItemWrapper = styled.div`
  ${clamp('padding-bottom', 36, 48)};
  ${clamp('padding-top', 36, 48)};
  position: relative;

  &::before {
    transform-origin: top left;
    transition: transform 0.7s ${cubicBezier.inOut}, opacity 0.7s ${cubicBezier.inOut};
    transform: ${props => props.inView ? 'scaleX(1)' : 'scaleX(0)' } ;
    opacity: ${props => props.inView ? '1' : '0' } ;
  }

  &:before {
    background-color: ${colors.darkblue};
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const ItemContent = styled.div`
  padding-bottom: 3.6rem;

  ${mq.tabletP} {
    padding-left: 2rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  ${mq.tabletL} {
    ${clamp('padding-left', 40, 70)};
  }
`

export const ItemExcerpt = styled.div`
  padding-top: 0;
  display: none;

  ${mq.tabletP} {
    padding-top: 1rem;
    display: block;
  }
`

export const ItemButton = styled.div`
  ${mq.tabletP} {
    text-align: right;
  }
`