import { RoundedImageWrapper } from '@components/RoundedImage/index.style'
import { colors } from '@styles/vars/colors.style'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { Label, Super2, TextBody } from '@styles/vars/textStyles.style'
import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { mq } from '@styles/vars/media-queries.style'
import { GridItemMain } from '@components/GridItem/index.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { AnimateImageMain } from '@components/animation/AnimateImage/index.style'

export const HeroWrapper = styled.div`
  ${clamp('padding-top', 180, 240)};
  position: relative;
  width: 100%;

  &:before {
    background-color: ${colors.darkblue};
    ${clamp('bottom', 100, 125)};
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
`

export const HeroSlides = styled.div`
  display: grid;
`

export const HeroSlide = styled.div.attrs(props => ({
  style: {
    pointerEvents: props.show ? `auto` : `none`,
    zIndex: props.show ? 2 : 1,
  },
}))`
  grid-area: 1 / 1;

  ${GridItemMain}:first-child {
    position: relative;
    z-index: 2;
  }
`

export const HeroImage = styled.div`
  margin-top: -2rem;

  ${mq.tabletL} {
    margin-top: 0;
  }

  img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  ${AnimateImageMain},
  ${RoundedImageWrapper} {
    height: 100%;

    > div {
      height: 100%;
      opacity: ${props => (props.show ? 1 : 0)};
      transform: scale(${props => (props.show ? 1 : 1.1)});
      transition: ${props =>
        props.show
          ? `opacity 1.2s ${cubicBezier.inOut}, transform 1.2s ${cubicBezier.out}`
          : `opacity 0.6s, transform 0s 1.2s`};

      ${mq.deskM} {
        transition: ${props =>
          props.show
            ? `opacity 1.2s ${cubicBezier.inOut}, transform 1.2s ${cubicBezier.out}`
            : `opacity .6s .6s, transform 0s 1.2s`};
      }
    }
  }

  ${Label} {
    opacity: ${props => (props.show ? 1 : 0)};
    position: absolute;
    ${clamp('left', 24, 48)};
    ${clamp('top', 20, 44)};
    transition: opacity 1s ${cubicBezier.out};
    z-index: 2;
  }
`

export const HeroContent = styled.div`
  color: ${colors.light};
  text-align: center;

  ${mq.tabletL} {
    ${clamp('margin-bottom', 100, 174)};
    text-align: left;
  }

  ${mq.deskM} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 64rem;
  }

  ${Label} {
    display: inline-block;
    ${clamp('margin-bottom', 12, 44)};
  }

  ${TextBody} {
    margin-left: auto;
    margin-right: auto;
    max-width: 18em;
    ${clamp('padding-bottom', 48, 48)};

    ${mq.tabletL} {
      max-width: none;
    }
  }

  ${Super2} {
    margin-bottom: 2.2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 8em;

    ${mq.tabletL} {
      margin-left: 0;
      max-width: 100%;
    }
    ${mq.deskL} {
      max-width: 120%;
      width: 120%;
    }
  }
`

export const HeroContentInner = styled.div`
  position: relative;

  ${HeroImage} {
    display: none;

    ${mq.deskM} {
      display: block;
    }
  }
`

export const HeroContentText = styled.div`
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity ${props => (props.show ? 0 : 0.4)}s;
`

export const HeroLink = styled.div`
  position: relative;
  z-index: 2;

  ${mq.deskM} {
    ${clamp('bottom', 12, 24, breakpoints.deskM, breakpoints.contained)};
    ${clamp('left', 12, 24, breakpoints.deskM, breakpoints.contained)};
    position: absolute;
  }
`

export const HeroLineWrap = styled.ul`
  ${mq.tabletL} {
    ${clamp('bottom', 50, 75)};
    left: 0;
    position: absolute;
    right: 0;
  }
`

export const HeroLines = styled.ul`
  display: flex;
  list-style-type: none;
  margin-left: auto;
  margin-right: auto;
  ${clamp('margin-top', 36, 50)};
  width: 27.8rem;

  ${mq.tabletL} {
    width: 100%;
  }
`

export const HeroLine = styled.li`
  background-color: ${props => (props.active ? colors.blue : `#c6cff3`)};
  border-radius: 0.1rem;
  height: 0.2rem;
  overflow: hidden;
  margin: 0 0.3rem;
  width: 100%;
`

export const HeroLineProgress = styled.span`
  background-color: ${colors.blue};
  display: block;
  height: 100%;
  transform: scaleX(0);
  transform-origin: 0% 0%;
  width: 100%;
`
