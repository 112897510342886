import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  HeroWrapper,
  HeroSlides,
  HeroSlide,
  HeroImage,
  HeroLink,
  HeroContent,
  HeroContentInner,
  HeroContentText,
  HeroLineWrap,
  HeroLines,
  HeroLine,
  HeroLineProgress,
} from './index.style'
import RoundedImage from '@components/RoundedImage'
import Container from '@components/Container'
import gsap from 'gsap'
import { Label, Super2, TextBody } from '@components/TextStyles'
import Grid from '@components/Grid'
import { colors } from '@styles/vars/colors.style'
import GridItem from '@components/GridItem'
import Link from '@components/Link'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateHero from '@components/animation/AnimateHero'

const Hero = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const progressLines = useRef([])

  useEffect(() => {
    const nextSlide = () => {
      setActiveIndex(activeIndex < slides.length - 1 ? activeIndex + 1 : 0)
    }

    const iteration = gsap.fromTo(
      progressLines.current[activeIndex],
      {
        scaleX: 0,
      },
      {
        duration: 7,
        scaleX: 1,
        onComplete: nextSlide,
        clearProps: 'all',
        ease: 'none',
      }
    )

    return () => {
      if (iteration) iteration.kill()
    }
  }, [activeIndex, slides, progressLines])

  return (
    <HeroWrapper>
      <Container>
        <HeroSlides>
          {React.Children.toArray(
            slides.map((slide, slideIndex) => (
              <HeroSlide show={slideIndex === activeIndex}>
                <Grid>
                  <GridItem
                    tabletL={4}
                    tabletLStart={2}
                    tabletLAlign={'flex-end'}
                    desk={3}
                    deskStart={2}
                    deskAlign={'flex-end'}
                  >
                    <HeroContent>
                      <HeroContentInner>
                        <HeroContentText show={slideIndex === activeIndex}>
                          <AnimateFadeIn animate={slideIndex === activeIndex}>
                            <Label color={colors.blue} dot>
                              {slide.label}
                            </Label>
                          </AnimateFadeIn>
                          <Super2>
                            <AnimateSplitText
                              animate={slideIndex === activeIndex}
                            >
                              {slide.title}
                            </AnimateSplitText>
                          </Super2>

                          <TextBody as="div">
                            <AnimateFadeIn animate={slideIndex === activeIndex}>
                              {slide.content}
                            </AnimateFadeIn>
                          </TextBody>
                        </HeroContentText>

                        <HeroImage
                          show={slideIndex === activeIndex}
                          delay={0.3}
                        >
                          <RoundedImage>
                            <AnimateHero>
                              <GatsbyImage
                                image={slide.smallImage.gatsbyImageData}
                                alt={slide.smallImage.description}
                                loading="eager"
                              />
                            </AnimateHero>
                          </RoundedImage>
                        </HeroImage>
                        <HeroContentText show={slideIndex === activeIndex}>
                          <HeroLink>
                            <AnimateFadeIn animate={slideIndex === activeIndex}>
                              <Link
                                iconOrientation={slide.link.url ? 'vertical' : 'horizontal'}
                                url={slide.link.url}
                                page={slide.link.page}
                              >
                                {slide.link.text}
                              </Link>
                            </AnimateFadeIn>
                          </HeroLink>
                        </HeroContentText>
                      </HeroContentInner>
                    </HeroContent>
                  </GridItem>
                  <GridItem
                    tabletL={6}
                    tabletLStart={7}
                    tabletLAlign={'flex-end'}
                  >
                    <HeroImage show={slideIndex === activeIndex} delay={0.6}>
                      <RoundedImage>
                        <AnimateHero scale={1.5} transformOrigin={`top right`}>
                          <GatsbyImage
                            image={slide.largeImage.gatsbyImageData}
                            alt={slide.largeImage.description}
                            loading="eager"
                          />
                        </AnimateHero>
                      </RoundedImage>
                    </HeroImage>
                  </GridItem>
                </Grid>
              </HeroSlide>
            ))
          )}
        </HeroSlides>
      </Container>
      <HeroLineWrap>
        <Container>
          <Grid>
            <GridItem tabletL={4} tabletLStart={2} desk={3} deskStart={2}>
              <HeroLines>
                {React.Children.toArray(
                  slides.map((slide, slideIndex) => (
                    <HeroLine active={activeIndex > slideIndex}>
                      <HeroLineProgress
                        ref={el => (progressLines.current[slideIndex] = el)}
                      />
                    </HeroLine>
                  ))
                )}
              </HeroLines>
            </GridItem>
          </Grid>
        </Container>
      </HeroLineWrap>
    </HeroWrapper>
  )
}

Hero.propTypes = {
  slides: PropTypes.array,
}

export default Hero
