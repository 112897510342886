import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { animation } from '@styles/vars/animation.style'
import AnimateImage from '@components/animation/AnimateImage'
import RoundedImage from '@components/RoundedImage'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from '@components/Link'
import Spacer from '@components/Spacer'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import {
  Heading3,
  TextBodySmall
} from '@components/TextStyles'
import {
  ItemWrapper,
  ItemContent,
  ItemButton,
  ItemExcerpt
} from './index.style'

const Item = ({ article }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: animation.rootMargin,
  })

  return (
    <ItemWrapper ref={ref} inView={inView}>
      <Grid>
        <GridItem tabletPOrder={1} tinyOrder={2} tabletP={4} desk={3}>
          <AnimateFadeIn>
            <RoundedImage>
              <AnimateImage delay={0}>
                <GatsbyImage
                  image={article.introImage.gatsbyImageData}
                  alt={article.introImage.description}
                />
              </AnimateImage>
            </RoundedImage>
          </AnimateFadeIn>
        </GridItem>
        <GridItem tabletPOrder={2} tinyOrder={1} tabletP={5} tabletPAlign={'flex-end'} desk={5}>
          <ItemContent>
            <AnimateSplitText>
              <Heading3>{article.title}</Heading3>
            </AnimateSplitText>
            <ItemExcerpt>
              <AnimateSplitText>
                <TextBodySmall maxWidth={22}>{article.excerpt}</TextBodySmall>
              </AnimateSplitText>
            </ItemExcerpt>
          </ItemContent>
        </GridItem>
        <GridItem tabletPOrder={3} tinyOrder={3} tabletP={3} desk={4} tabletPAlign={'flex-end'}>
          <Spacer size={30} />
          <AnimateFadeIn>
            <ItemButton>
              <Link page={article}>Full Article</Link>
            </ItemButton>
          </AnimateFadeIn>
        </GridItem>
      </Grid>
    </ItemWrapper>
  )
}

Item.propTypes = {
  article: PropTypes.object
}

export default Item