import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { AnimateImageMain, AnimateImageMask } from './index.style'
import { animation } from '@styles/vars/animation.style'

const AnimateHero = ({
  children,
  delay = animation.maskShowDuration,
  triggerOnce = true,
  animate = true,
  hover = true,
  scale = 2,
  translate = `0, 0, 0`,
  transformOrigin = 'center center'
}) => {
  const [ref, inView] = useInView({
    triggerOnce,
    rootMargin: animation.rootMargin,
  })

  return (
    <AnimateImageMask ref={ref}>
      <AnimateImageMain
        inView={inView && animate}
        delay={delay}
        hover={hover}
        scale={scale}
        translate={translate}
        transformOrigin={transformOrigin}
      >
        {children}
      </AnimateImageMain>
    </AnimateImageMask>
  )
}

AnimateHero.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  triggerOnce: PropTypes.bool,
  animate: PropTypes.bool,
  hover: PropTypes.bool,
  scale: PropTypes.number,
  translate: PropTypes.string,
  transformOrigin: PropTypes.string
}

export default AnimateHero
