import styled from 'styled-components'
import { cubicBezier } from '@styles/vars/cubicBezier.style'
import { animation } from '@styles/vars/animation.style'

export const AnimateImageMask = styled.div`
  overflow: hidden;
`

export const AnimateImageMain = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${
      props.inView ? props.delay : animation.maskShowDuration
    }s`,
  },
}))`
  will-change: transform;
  transform: ${props => (
    props.inView
    ? 'scale(1) translate3D(0, 0, 0)'
    : `scale(${props.scale})  translate3D(${props.translate})`
  )};
  transform-origin: ${props => props.transformOrigin};
  transition: transform 1.2s ${cubicBezier.out};

  &:hover {
    transform: ${props => (props.hover ? 'scale(1.03)' : 'scale(1)')};
  }
`
