import React from 'react'
import PropTypes from 'prop-types'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Button from '@components/Button'
import AnimateFadeIn from '@components/animation/AnimateFadeIn'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import IconArrowRight from '@svgs/IconArrowRight'
import Item from './Item'
import {
  Heading1,
  TextBody
} from '@components/TextStyles'
import {
  ListWrapper,
  ItemButton,
} from './index.style'

const List = ({
  articles,
  heading,
  subtitle,
  archiveLink,
  archiveLinkLabel,
}) => {
  return (
    <ListWrapper>
      <Container>
        <Grid>
          <GridItem desk={6} tabletP={6}>
            <Heading1>
              <AnimateSplitText>{heading}</AnimateSplitText>
            </Heading1>
            <Spacer size={24} />
            <TextBody>
              <AnimateSplitText>{subtitle}</AnimateSplitText>
            </TextBody>
          </GridItem>
          <GridItem desk={6} tabletP={6} tabletPAlign={'flex-end'}>
            <Spacer size={[24, 30]} />
            <AnimateFadeIn>
              <ItemButton>
                <Button
                  type="internalLink"
                  to={archiveLink}
                  iconRight={<IconArrowRight />}
                  buttonSize="large"
                >
                  {archiveLinkLabel}
                </Button>
              </ItemButton>
            </AnimateFadeIn>
          </GridItem>
          <Spacer size={58} />
        </Grid>
        
        {React.Children.toArray(articles.map((article) => {
          return <Item article={article} />
        }))}

        <Spacer size={[24, 48]} />
      </Container>
    </ListWrapper>
  )
}

List.propTypes = {
  articles: PropTypes.array,
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  archiveLink: PropTypes.string,
  archiveLinkLabel: PropTypes.string
}

export default List
